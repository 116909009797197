import axios from 'axios'
// import store from '../store';
// import router from '../router';

// 创建axios实例

const hostList = {
  '9110': 'https://pcwallpaper.upupoo.com',
  '9500': 'https://search.upupoo.com',
  '9020': 'https://register.upupoo.com',
  '9999': 'https://website.upupoo.com',
  // '9110': 'http://pcwallpaper.upupoo.com',
  // '9500': 'http://search.upupoo.com',
  // '9020': 'http://register.upupoo.com',
  // '9999': 'http://website.upupoo.com',


    // '9090': 'http://backend.upupoo.com',
    // '9190': 'http://author.upupoo.com',
    // '9200': 'http://message.upupoo.com',
    // '9290': 'http://social.upupoo.com',
    // '9490': 'http://token.upupoo.com',
    // '9750': 'http://wallet.upupoo.com',
    // '9120': 'http://www.upupoo.com',
}
const whiteList = []
const askList = []

const service = axios.create({
    baseURL: 'http://192.168.0.226:9100',
    timeout: 30000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json',
    }
})

window._axiosPromiseArr = []
service.interceptors.request.use(config => {
    // console.log(config);
    // if (!screenIsWhiteRouter(whiteList, config.url)) {

    // }
    // config.headers.token = store.state.user.token;
    if (config.headers.port) {
        config.url = hostList[config.headers.port] + config.url;
    }
    if (config.url && askList.every(item => !config.url.includes(item))) {
        config.cancelToken = new axios.CancelToken(cancel => {
            window._axiosPromiseArr.push({
                cancel
            })
        })
    }

    return config
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        //console.log(res)
        if (res.success) {
            return res;
        } else {
            return res;
        }

    },
    error => {
        let originalRequest = error.config
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
            return Promise.reject('超时')
        } else if (error.message === 'Network Error') {
            return Promise.reject('无网')
        } else if (error.response) {
            const res = error.response.data
            // if (res.status == '404') {}
            return Promise.reject(res)
        } else {
            return Promise.reject(error)
        }
    }
)


export default service